'use strict';

import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { NbThemeModule, NbMenuModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbAuthModule,
         NbPasswordAuthStrategy,
         NbOAuth2AuthStrategy,
         NbAuthOAuth2Token,
         NbOAuth2ClientAuthMethod,
         NbOAuth2ResponseType,
         NbOAuth2GrantType,
         NbAuthJWTInterceptor,
         NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { throwIfAlreadyLoaded } from '../module-import-guard';
import { environment as env } from '@env/environment';

export interface NbAuthSocialLink {
  link?: string;
  url?: string;
  target?: string;
  title?: string;
  icon?: string;
}
const socialLinks: NbAuthSocialLink[] = [];

const authStrategies = [
  NbPasswordAuthStrategy.setup({
    name: 'password',
    baseEndpoint: env.oauthUrl,
    requestPass: {
      redirect: {
        success: null,
        failure: null
      },
      endpoint: 'request-password',
      defaultErrors: ['Something went wrong, please try again.'],
      defaultMessages: ['Reset password instructions have been sent to your email.']
    },
    resetPass: {
      endpoint: 'reset-password',
      resetPasswordTokenKey: 'token',
      redirect: {
        success: '/auth/login',
        failure: null
      },
      defaultErrors: ['Something went wrong, please try again.'],
      defaultMessages: ['Your password has been successfully changed.']
    }
    // register: {
    //   alwaysFail: false,
    //   requireValidToken: true,
    //   defaultErrors: ['Something went wrong, please try again.'],
    //   defaultMessages: ['You have been successfully registered.']
    // },
  }),
  NbOAuth2AuthStrategy.setup({
    name: 'oauth2',
    baseEndpoint: env.oauthUrl,
    clientId: env.oauthClientId,
    clientSecret: env.oauthClientSecret,
    clientAuthMethod: NbOAuth2ClientAuthMethod.REQUEST_BODY,
    defaultErrors: ['Your credentials are invalid, please try again.'],
    // authorize: {
      // endpoint: "token",
      // responseType: NbOAuth2ResponseType.TOKEN,
      // redirectUri: "/",`
      // scope: "*",
      // requireValidToken: true
    // },
    token: {
      class: NbAuthOAuth2Token,
      grantType: NbOAuth2GrantType.PASSWORD,
      // redirectUri: "/",
      scope: '*',
      requireValidToken: true
    },
    refresh: {
      endpoint: 'token',
      scope: '*',
      requireValidToken: true
    },
    redirect: {
      success: '/secure'
    }
  })
];
const formSetting = {
  login: {
    strategy: 'oauth2',
    rememberMe: false,
    showMessages: {
      success: true,
      error: true
    },
    socialLinks
  },
  logout: {
    strategy: 'oauth2'
  },
  requestPassword: {
    strategy: 'password',
    showMessages: {
      success: true,
      error: true
    },
    socialLinks
  },
  resetPassword: {
    strategy: 'password',
    showMessages: {
      success: true,
      error: true
    },
    socialLinks
  }
  // register: {
  //   strategy: 'password',
  //   showMessages: {
  //     success: true,
  //     error: true
  //   },
  //   terms: true,
  //   socialLinks
  // },
  // resetPassword: {
  //   redirectDelay: 500,
  //   strategy: 'email',
  //   showMessages: {
  //     success: true,
  //     error: true,
  //   },
  //   socialLinks: socialLinks,
  // },
  // validation: {
  //   password: {
  //     required: true,
  //     minLength: 4,
  //     maxLength: 50,
  //   },
  //   email: {
  //     required: true,
  //   },
  //   fullName: {
  //     required: false,
  //     minLength: 4,
  //     maxLength: 50,
  //   },
  // }
};

@NgModule({
    declarations: [],
    imports: [
        NbThemeModule.forRoot({
            name: 'biohackk'
        }),
        NbEvaIconsModule,
        NbAuthModule.forRoot({
            strategies: authStrategies,
            forms: formSetting
        }),
        NbMenuModule.forRoot()
    ],
    exports: [
        NbAuthModule,
        NbMenuModule
    ],
    providers: [],
    bootstrap: []
})
export class NebularModule {
  constructor(@Optional() @SkipSelf() parentModule: NebularModule) {
    throwIfAlreadyLoaded(parentModule, 'NebularModule');
  }

  static forRoot(): ModuleWithProviders<NebularModule> {
    return {
      ngModule: NebularModule,
      providers: []
    };
  }
}
