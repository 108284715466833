'use strict';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { UserRole } from '@shared/interfaces/user-roles';
import { Subject, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({providedIn: 'root'})
export class RoleService {
  public role: UserRole = null;
  public subjectRole: Subject<UserRole> = new Subject<UserRole>();

  constructor(private router: Router, private local: LocalStorageService, private authService: AuthService) {}

  setRole(role: UserRole): void {
    this.role = role;
    this.updateLocalRole();
    this.subjectRole.next(role);
  }

  resetRole(): void {
    this.setRole(null);
  }

  async updateRoute(url = ''): Promise<void> {
    if (!url && this.role === 'consultants' && this.authService?.user?.roles?.sdr) {
      await this.router.navigate([`/secure/consultants/revenue/leads`]);
    } else {
      await this.router.navigate([(url) ? url : '/secure/' + this.role]);
    }
  }

  getLocalRole(): UserRole {
    return this.local.getItem(`role`) as UserRole;
  }

  updateLocalRole(): void {
    if (this.role) {
      this.local.setItem('role', this.role);
    }
  }

  monitorRole(): Observable<UserRole> {
    return this.subjectRole.asObservable();
  }
}
