'use strict';

/**
 * Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
// import { NotificationService } from '@app/@core/services/notification';
import { environment as env } from '@env/environment';

@Injectable({providedIn: 'root'})
export class ErrorsHandlerService extends ErrorHandler {
  constructor() {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    console.error(`Errors Handler :: Error Detected!`);

    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        console.error(`Unable to process Request(s) as you are currently offline!`);
      } else {
        // Handle Http Error (error.status === 403, 404...)
        if (error.status) { console.error(`Error Code: ${error.status}`); }
        if (error.statusText) { console.error(`Error Message: ${error.statusText}`); }
      }
    } else {
      let displayMessage = 'An error occurred.';
      if (!env.production) {
          displayMessage += ' See console for details.';
      }
    //   this.notificationsService.error(displayMessage);
      console.error(`${displayMessage}`);
    }

    super.handleError(error);
  }
}
