'use strict';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  })
};

const httpOptionsUpload = {
  headers: new HttpHeaders({ Accept: 'application/json' })
};

@Injectable({providedIn: 'root'})
export class ApiService {
  timeoutLength: number = 5 * 60 * 1000;

  constructor(private http: HttpClient) {}

  download(path: string): Observable<any> {
    return this.http.get(`${env.apiUrl}${path}`, { responseType: 'blob' }).pipe(
      timeout(this.timeoutLength),
      catchError(err => this.handleError(err))
    );
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${env.apiUrl}${path}`, { params }).pipe(
      timeout(this.timeoutLength),
      catchError(err => this.handleError(err))
    );
  }

  put(path: string, body = {}): Observable<any> {
    return this.http.put(`${env.apiUrl}${path}`, body, httpOptions).pipe(
      timeout(this.timeoutLength),
      catchError(err => this.handleError(err))
    );
  }

  post(path: string, body = {}): Observable<any> {
    return this.http.post(`${env.apiUrl}${path}`, body, httpOptions).pipe(
      timeout(this.timeoutLength),
      catchError(err => this.handleError(err))
    );
  }

  // upload(path: string, body = {}): Observable<any> {
  //   return this.http.post(`${env.apiUrl}${path}`, body, httpOptionsUpload).pipe(
  //     timeout(this.timeoutLength),
  //     catchError(err => this.handleError(err))
  //   );
  // }

  upload(body = {}): Observable<any> {
    return this.http.post(`${env.apiUrl}vapor/signed-storage-url`, body, httpOptionsUpload).pipe(
      timeout(this.timeoutLength),
      catchError(err => this.handleError(err))
    );
  }

  patch(path: string, body = {}): Observable<any> {
    return this.http.patch(`${env.apiUrl}${path}`, body, httpOptions).pipe(
      timeout(this.timeoutLength),
      catchError(err => this.handleError(err))
    );
  }

  delete(path: string): Observable<any> {
    return this.http.delete(`${env.apiUrl}${path}`, httpOptions).pipe(
      timeout(this.timeoutLength),
      catchError(err => this.handleError(err))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError(error: Error | HttpErrorResponse | any) {
    let status = null;

    // Log Errors into Backend
    try {
      this.http.post(`${env.apiUrl}api/logs`, {
        data: error
      }, httpOptions);
    } catch (e) {
      console.error(`Error during passing logging information to server.`, e);
    }

    if (error['name'] === 'TimeoutError') {
      status = 'request-timeout';
      // console.log('Request Timeout.', error);
    }
    else if (error.error &&
             error.error.errors &&
           ((error.error.errors.email && error.error.errors.email[0] === 'The email has already been taken.') ||
            (error.error.status === 'email-exists'))) {
      status = 'email-exists';
    }
    // else if (error['url'] == 'https://secure.legacyfa-asia.com/auth/check-google2fa') return of(error['error']);
    else {
      status = 'unexpected-error';
      console.log('Unexpected error has occurred.', error);
    }

    // TODO: better job of transforming error for user consumption
    // this.log(`${error} failed: ${error.message}`);

    // TODO: send the error to remote logging infrastructure

    // Let the app keep running by returning an empty result.
    return of({
      status,
      errors: true,
      name: error.name,
      message: error.message
    });
  }
}
