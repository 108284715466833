'use strict';

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as moment from 'moment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SimpleMdmRedirect } from './simple-mdm-redirect.component';
// import * as Sentry from '@sentry/angular';

moment.updateLocale('en', {
  calendar : {
    lastDay : '[Yesterday at] h:mm A',
    sameDay : '[Today at] h:mm A',
    nextDay : '[Tomorrow at] h:mm A',
    lastWeek : '[Last] dddd [at] h:mm A',
    nextWeek : 'dddd [at] h:mm A',
    sameElse : 'L [at] h:mm A'
  }
});

@NgModule({
  declarations: [
    AppComponent,
    SimpleMdmRedirect
  ],
  imports: [
    AppRoutingModule,
    CoreModule.forRoot(),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:3000'
    })
  ],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false
    //   })
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router]
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   // eslint-disable-next-line @typescript-eslint/no-empty-function
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
