'use strict';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ApiAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // canActive can return Observable<boolean>, which is exactly what isAuthenticated returns
    return this.authService.checkAuthentication().pipe(
      map((response: any) => {
        if (response.access) {
          return true;
        }
        // await this.router.navigate(['/login']);
        return false;
      }), catchError((error) =>
        // await this.router.navigate(['/login']);
         of(false)
      )
    );
  }
}
