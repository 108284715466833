'use strict';
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable, of, Subscription } from 'rxjs';

import { NbAuthService } from '@nebular/auth';
import { ApiService } from '@core/services/api/api.service';
import { PusherService } from '@core/services/pusher/pusher.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import User from '@shared/interfaces/user';
import Client from '@shared/interfaces/client';
import Consultant from '@shared/interfaces/consultant';
import Doctor from '@shared/interfaces/doctor';
import Lead from '@shared/interfaces/lead';
import Affiliate from '@shared/interfaces/affiliate';
import DashboardMeasurement from '@shared/interfaces/dashboard-measurements';
import Programme from '@shared/interfaces/programme';
import { UserRole } from '@shared/interfaces/user-roles';
import { ToastrService } from 'ngx-toastr';

interface AffiliateData {
  uuid: string | null;
  profile: Affiliate | null;
}

interface ClientData {
  uuid: string | null;
  profile: Client | null;
  measurements: DashboardMeasurement | null;
  current_programme: Programme | null;
  upcoming_programme: Programme | null;
  past_programme: Programme | null;
  latest_measurement: any;
  latest_journal: any;
}

interface ConsultantData {
  uuid: string | null;
  profile: Consultant | null;
}

interface DoctorData {
  uuid: string | null;
  profile: Doctor | null;
}

interface LeadData {
  uuid: string | null;
  profile: Lead | null;
}

@Injectable({providedIn: 'root'})
export class AuthService implements OnDestroy {
  subjectAuthenticated: Subject<boolean> = new Subject<boolean>();
  userAuthenticated = false;
  user?: User;
  userHasMultipleRoles = false;
  defaultRole: UserRole = null;

  affiliateData: AffiliateData = { uuid: null, profile: null };
  clientData: ClientData = { uuid: null, measurements: null,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  current_programme: null, upcoming_programme: null, past_programme: null, latest_measurement: null, latest_journal: null, profile: null };
  consultantData: ConsultantData = { uuid: null, profile: null };
  doctorData: DoctorData = { uuid: null, profile: null };
  leadData: LeadData = { uuid: null, profile: null };

  private nbIsAuth?: Subscription;
  private nbAuthChange?: Subscription;

  constructor(
    private nbAuthService: NbAuthService,
    private router: Router,
    private api: ApiService,
    private pushService: PusherService,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService
  ) {}

  ngOnDestroy(): void {
    this.nbIsAuth?.unsubscribe();
    this.nbAuthChange?.unsubscribe();
  }

  monitorAuthentication(): Observable<any> {
    return this.subjectAuthenticated.asObservable();
  }

  checkAuthentication(): Observable<boolean> {
    return this.api.get('api/authentication');
  }

  checkProfileUser(): Observable<boolean> { return this.api.get('api/profile-setup/user'); }
  checkProfileClient(): Observable<boolean> { return this.api.get('api/profile-setup/client'); }
  checkProfileLead(): Observable<boolean> { return this.api.get('api/profile-setup/lead'); }
  checkProfileDoctor(): Observable<boolean> { return this.api.get('api/profile-setup/doctor'); }
  checkProfileConsultant(): Observable<boolean> { return this.api.get('api/profile-setup/consultant'); }
  checkProfileAffiliate(): Observable<boolean> { return this.api.get('api/profile-setup/affiliate'); }

  // Get Authenticated User
  retrieveUser() {
    this.nbIsAuth = this.nbAuthService.isAuthenticated().subscribe(async nbAuthenticated => {
      if (nbAuthenticated === true) {
        const response = await this.api.post('oauth/me').toPromise();
        await this.handleUserResponse(response);
      }
    });
  }

  // getPoints(): Observable<any> {
  //   return this.api.get(`api/users/points`);
  // }

  async handleUserResponse(res: any) {
    // console.log(`User Response:`, res);
    if (res.errors === false) {
      this.user = res.data as User;

      // console.log(this.user);

      if (this.user.activated !== true) {
        await this.logout('account-invalid');
      }

      let rolesCount = 0;

      // if (res.data.roles.lead && res.hasOwnProperty('leadData')) {
      //   this.leadData = res.leadData;
      //   this.defaultRole = 'leads';
      //   rolesCount++;
      // }

      // if (res.data.roles.affiliate && res.hasOwnProperty('affiliateData')) {
      //   this.affiliateData = res.affiliateData;
      //   this.defaultRole = 'affiliates';
      //   rolesCount++;
      // }

      if (res.data.roles.customerService && res.hasOwnProperty('customerServiceData')) {
        this.doctorData = res.doctorData;
        this.defaultRole = 'customer-service';
        rolesCount++;
      }

      if (res.data.roles.doctor && res.hasOwnProperty('doctorData')) {
        this.doctorData = res.doctorData;
        this.defaultRole = 'doctors';
        rolesCount++;
      }

      if (res.data.roles.consultant && res.hasOwnProperty('consultantData')) {
        this.consultantData = res.consultantData;
        this.defaultRole = 'consultants';
        rolesCount++;
      }

      if (res.data.roles.client && res.hasOwnProperty('clientData')) {
        this.clientData = res.clientData;
        this.defaultRole = 'clients';
        rolesCount++;
      }

      if (res.data.roles.admin) {
        this.defaultRole = 'admin';
        rolesCount++;
      }

      //

      if (res.data.roles.medical) {
        this.defaultRole = 'medical';
        rolesCount++;
      }

      if (res.data.roles.sdr) {
        this.defaultRole = 'sdr';
        rolesCount++;
      }

      //

      if (!this.defaultRole || !['customer-service', 'doctors', 'consultants', 'clients', 'admin', 'sdr', 'medical'].includes(this.defaultRole)) {
        console.log('No Valid Roles Available');
        await this.logout('account-invalid');
      }

      // Update intercom user
      // (window as any).Intercom('update', {
      //   api_base: 'https://api-iam.intercom.io',
      //   app_id: 'sv01irxb',
      //   user_id: this.user?.uuid,
      //   email: this.user?.email,
      //   user_hash: this.user?.intercom_key,
      //   last_request_at: (new Date()).getTime()/1000,
      //   name: this.user?.displayName,
      //   phone: this.user?.mobile,
      //   avatar: {
      //     type: 'avatar',
      //     image_url: this.user?.avatar
      //   }
      // });

      this.userAuthenticated = true;
      this.userHasMultipleRoles = !!(rolesCount > 1);
      this.subjectAuthenticated.next(true);
    } else {
      console.error(`Invalid OAuth2 Token.`);
      await this.logout(`oauth-token-invalid`);
    }
  }

  // Authenticate through token via email
  requestLoginEmail(email: string, broadcast_id: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.api.post('oauth/request-login-email', { email, broadcast_id });
  }

  // Verify Token from Login Email
  verifyLoginEmailToken(email: string, token: string): Observable<any> {
    return this.api.post('oauth/verify-login-email-token', { email, token });
  }

  // Verify OTP from Login Email
  verifyLoginEmailOTP(email: string, otp: string): Observable<any> {
    return this.api.post('oauth/verify-login-email-otp', { email, otp });
  }

  // Verify Token from Login Email
  verifiedLoginEmailToken(email: string, broadcast_id: string): Observable<any> {
    return this.api.post('oauth/verified-login-email-token', { email, broadcast_id });
  }

  invalidateSession(): void {
    this.localStorageService.removeItem('auth-redirect');
    this.localStorageService.removeItem('role');
    this.nbAuthService.logout('oauth2');
    localStorage.removeItem('auth_app_token');
    this.user = undefined;

    this.pushService.channelAuthentication?.unbind_all();
    this.pushService.channelConversations?.unbind_all();
  }

  async logout(status: string = 'logout'): Promise<void> {
    // this.api.post('oauth/logout', {});
    this.invalidateSession();

    if (status === 'logout') {
      this.toastr.success('You have successfully logged out.', 'Successfully Logged Out!');
      await this.router.navigate(['/'], {queryParams: { status }});

    } else {

      if (status === 'multiple-logins') {
        this.toastr.warning('Due to security concerns, we will only allow one device login per account.', 'Multiple logins detected!');
      }  else if (status === 'oauth-token-invalid') {
        this.toastr.warning('Invalid session, please login again.', 'Invalid Authentication!');
      } else if (status === 'account-invalid') {
        this.toastr.error('Please contact our support staff if you did not expect this error.', 'Account Unavailable!');
      }

      await this.router.navigate(['/auth/login'], {queryParams: { status }});
    }
  }
}
