'use strict';

import { Injectable } from '@angular/core';

const APP_PREFIX = 'Biohackk-';

const isJSON = (str: any) => {
  try {
      const obj = JSON.parse(str);
      if (obj && typeof obj === 'object' && obj !== null) {
          return true;
      }
  } catch (err) {}
  return false;
};

@Injectable({providedIn: 'root'})
export class SessionStorageService {
  static loadInitialState() {
    return Object.keys(sessionStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map(key =>
            key
              .split('-')
              .map(
                (token, index) =>
                  index === 0
                    ? token
                    : token.charAt(0).toUpperCase() + token.slice(1)
              )
              .join('')
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(sessionStorage.getItem(storageKey) || '');
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  setItem(key: string, value: any): void {
    if (typeof value === 'string') {
      sessionStorage.setItem(`${APP_PREFIX}${key}`, value);
    } else if (value && isJSON(value)) {
      sessionStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
    }
  }

  getItem(key: string): string | null {
    const item = sessionStorage.getItem(`${APP_PREFIX}${key}`) || null;
    if (typeof item === 'string') {
      return item;
    } else if (item && isJSON(item)) {
      return JSON.parse(item);
    } else {
      return item;
    }
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(`${APP_PREFIX}${key}`);
  }
}
