'use strict';

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({providedIn: 'root'})
export class RedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // window.location.href = route.data['externalUrl'];
    window.open(route.data['externalUrl'], '_blank');

    if (this.router.routerState.snapshot.url === '') {
      await this.router.navigate(['/']);
    }

    return false;
  }
}
