'use strict';

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { NbAuthService } from '@nebular/auth';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private nbAuthService: NbAuthService, private router: Router, private session: SessionStorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // canActive can return Observable<boolean>, which is exactly what isAuthenticated returns
    return new Promise(resolve =>
      this.nbAuthService.isAuthenticated().subscribe(async (authenticated) => {
        if (!authenticated) {
          if (state.url.startsWith('/secure/')) {
            this.session.setItem('urlAfterAuth', state.url);
          }
          await this.router.navigate(['/auth']);
          resolve(false);
        } else {
          resolve(true);
        }
      })
    );
  }
}
