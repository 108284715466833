'use strict';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-simple-mdm-redirect',
  template: `redirecting...`,
  styles: []
})
export class SimpleMdmRedirect implements OnInit {
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const searchParams = new URLSearchParams(window.location.search);
    let newUrl = 'https://a.simplemdm.com/device/enrollment/well_known/946a7a46-5cc4-4932-b8f3-35da06f45068?';
    for (const [key, value] of searchParams.entries()) {
      newUrl += `${key}=${value}&`;
    }
    // this.route.queryParams.subscribe((params, data) => {
    //     console.log(params, data);
    // });
    // window.location.href = 'https://a.simplemdm.com/device/enrollment/well_known/946a7a46-5cc4-4932-b8f3-35da06f45068';
  }
}
