'use strict';

import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import Pusher, { Channel } from 'pusher-js';

@Injectable({providedIn: 'root'})
export class PusherService implements OnDestroy {

  public channelAuthentication?: Channel;
  public subjectMultipleLogins: Subject<{data: string}> = new Subject();
  public subjectEmailToken: Subject<{data: string}> = new Subject();

  public channelConversations?: Channel;
  public subjectNewConversation: Subject<{data: string}> = new Subject();
  public subjectNewMessage: Subject<{data: string}> = new Subject();
  public subjectNewMemo: Subject<{data: string}> = new Subject();

  public channelCalendar?: Channel;

  private pusherClient: Pusher;

  constructor() {
    this.pusherClient = new Pusher('416d9c7f50f167e4ccac', { cluster: 'ap1', forceTLS: true, disableStats: true });
    this.channelAuthentication = this.pusherClient.subscribe('authentication');
    this.channelConversations = this.pusherClient.subscribe('conversations');
    this.channelCalendar = this.pusherClient.subscribe('calendar');
  }

  ngOnDestroy(): void {
    this.pusherClient?.unbind_global();
    this.pusherClient?.unsubscribe('authentication');
    this.pusherClient?.unsubscribe('conversations');
    this.pusherClient?.unsubscribe('calendar');

    this.channelConversations?.unbind_all();
    this.channelConversations?.unsubscribe();

    this.channelCalendar?.unbind_all();
    this.channelCalendar?.unsubscribe();

    this.pusherClient.unbind_global();
    this.pusherClient.unsubscribe('authentication');
    this.pusherClient.unsubscribe('conversations');
    this.pusherClient.unsubscribe('calendar');
  }

  // Events for Channel: Authentication
  monitorMultipleLogins(): Observable<{data: string}> { return this.subjectMultipleLogins.asObservable(); }
  monitorEmailToken(): Observable<{data: string}> { return this.subjectEmailToken.asObservable(); }

  // Events for Channel: Conversations
  monitorNewConversations(): Observable<{data: string}> { return this.subjectNewConversation.asObservable(); }
  monitorNewMessage(): Observable<{data: string}> { return this.subjectNewMessage.asObservable(); }
  monitorNewMemo(): Observable<{data: string}> { return this.subjectNewMemo.asObservable(); }
}
