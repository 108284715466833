'use strict';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ApiProfileClientGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // canActive can return Observable<boolean>, which is exactly what isAuthenticated returns
    return this.authService.checkProfileClient().pipe(
      map((response: any) => {
        if (response.setup) { return true; }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['/secure/clients/setup']);
        return false;
      }), catchError(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['/secure']));
        return of(false);
      })
    );
  }
}
