'use strict';

import { Component, OnInit, OnDestroy, ApplicationRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { first, filter, map } from 'rxjs/operators';
import { NbAuthService, NbAuthResult } from '@nebular/auth';
import { environment as env } from '@env/environment';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, concat, interval } from 'rxjs';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styles: []
})
export class AppComponent implements OnInit, OnDestroy {
  authenticated = 'NULL';
  token = null;
  url = env.oauthUrl;

  private authSubscription?: Subscription;
  private logoutSubscription?: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authService: NbAuthService,
    private updates: SwUpdate,
    private appRef: ApplicationRef) {

    this.authSubscription = this.authService.onTokenChange()
      .subscribe((token) => {
        if (token.isValid()) {
          this.authenticated = 'TRUE';
          this.token = token.getPayload();
        } else {
          this.authenticated = 'FALSE';
          this.token = null;
        }
      });

      try {
        this.updates.activated.subscribe(event => {
          // console.log('Activated: Old version was', event.previous);
          // console.log('Activated: New version is', event.current);
        });

        this.updates.available.subscribe(async (event) => {
          // console.log('Available: Current version is', event.current);
          // console.log('Available: Available version is', event.available);
          await this.updates.activateUpdate();
          this.updateApp();
        });

        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
        const everySixHours$ = interval(6 * 60 * 60 * 1000);
        const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
        everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
      } catch (error) {
        console.error(`Error initializing Service Workers`, error);
      }
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        const titleSuffix = 'Biohackk | Your Health Our Priority';
        rt.data.subscribe((data: any) => this.titleService.setTitle((data?.meta?.title) ? `${data.meta.title} - ${titleSuffix}` : titleSuffix));
      });
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  updateApp(){
    document.location.reload();
    // console.log(`The app is updating right now...`);
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
    this.logoutSubscription?.unsubscribe();
  }

  async logout() {
    this.logoutSubscription = this.authService.logout('oauth2').subscribe((res: NbAuthResult) => {
      if (res['success'] === true) {
        // console.log(`Successfully logged out!`);
      }
    });
  }
}

// <div style="width: 100vw; overflow: scroll;
// background: #ffffffcc;
// padding: 50px;
// bottom: 0;
// z-index: 1;
// border-top: 1px solid #eee;">
// <p>ENV API: {{url}}</p>
// <p>Authenticated: {{authenticated}}</p>
// Token: <pre>{{token | json}}</pre>
// <button *ngIf="token" (click)="logout()">Logout</button>
// </div>

