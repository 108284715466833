'use strict';

import { NgModule, ModuleWithProviders, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { HttpClientModule, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbAuthJWTInterceptor, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NebularModule } from '../nebular/nebular.module';
import { NbMenuModule } from '@nebular/theme';
import { throwIfAlreadyLoaded } from '../module-import-guard';
import { EmbedVideo } from 'ngx-embed-video';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ImagekitioAngularModule } from 'imagekitio-angular';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { TooltipModule, TooltipOptions } from '@teamhive/ngx-tooltip';
import { ToastrModule } from 'ngx-toastr';
import { environment as env } from '@env/environment';
import { MomentModule } from 'ngx-moment';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxScrollspyModule } from '@uniprank/ngx-scrollspy';

// Services
import { ApiAuthGuard } from '@guards/api-auth-guard.service';
import { AuthGuard } from '@guards/auth-guard.service';
import { GuestGuard } from '@guards/guest-guard.service';
import { RedirectGuard } from '@guards/redirect-guard.service';
import { ApiProfileClientGuard } from '@guards/api-profile-client-guard.service';
import { ApiService } from '@core/services/api/api.service';
import { GoogleAnalyticsService } from '@core/services/api/google-analytics.service';
import { AuthService } from '@core/services/auth/auth.service';
import { PusherService } from '@core/services/pusher/pusher.service';
import { RoleService } from '@core/services/role/role.service';
import { ErrorsHandlerService } from '@core/services/errors-handler/errors-handler.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { PageService } from '@core/services/page/page.service';

// Non Authenticated Routes that do not need to add OAuth2 Tokens...
const filterInterceptorRequest = (req: HttpRequest<any>) => [
  // Auth Routes
  `${env.oauthUrl}request-login-email`,
  `${env.oauthUrl}verify-login-email-token`,
  `${env.oauthUrl}verified-login-email-token`,
  `${env.oauthUrl}request-password`,
  `${env.oauthUrl}reset-password`,
  `${env.oauthUrl}temp-token`,

  // Public Routes
  `${env.apiUrl}api/leads`,
  `${env.apiUrl}api/logs`,
  `${env.apiUrl}api/query-affiliate`,

  // Vapor Upload Routes
  // `${env.apiUrl}vapor/signed-storage-url`
  `https://biohackk-bucket.s3.ap-southeast-1.amazonaws.com`
].some((url) => req.url.includes(url));

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [],
    imports: [
        BrowserModule,
        HttpClientModule,
        NebularModule.forRoot(),
        NbMenuModule.forRoot(),
        EmbedVideo.forRoot(),
        SweetAlert2Module.forRoot(),
        ScrollToModule.forRoot(),
        MomentModule.forRoot(),
        ImagekitioAngularModule.forRoot({
            publicKey: `public_81Nq3/IQqD+y0KbBu25T25HdY4E=`,
            urlEndpoint: `${env.imageKitEndpoint}`,
            authenticationEndpoint: `https://biohackk.com/auth` //environment.authenticationEndpoint
        }),
        ToastrModule.forRoot(),
        TooltipModule.forRoot({
            followCursor: true,
            arrow: true,
            arrowType: 'sharp',
            allowHTML: true,
            maxWidth: 200,
            touch: false,
            trigger: 'mouseenter',
            delay: [20, 0]
        } as TooltipOptions),
        NgxMaskModule.forRoot(),
        NgxScrollspyModule.forRoot()
    ],
    exports: [],
    providers: [],
    bootstrap: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ApiService, AuthService, LocalStorageService, SessionStorageService, RoleService, PageService, PusherService,
        AuthGuard, GuestGuard, RedirectGuard, ApiAuthGuard,
        GoogleAnalyticsService,
        ApiProfileClientGuard,
        Meta,
        { provide: ErrorHandler, useClass: ErrorsHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true},
        { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: filterInterceptorRequest }
      ]
    };
  }
}
