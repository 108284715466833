'use strict';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@guards/auth-guard.service';
import { SimpleMdmRedirect } from './simple-mdm-redirect.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'secure',
    loadChildren: () => import('./modules/authenticated/authenticated.module').then(m => m.AuthenticatedModule),
    canActivate: [AuthGuard]
  },
  {
    path: '.well-known/com.apple.remotemanagement',
    component: SimpleMdmRedirect
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
