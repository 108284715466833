'use strict';

import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, Subscription } from 'rxjs';

// Breakpoints.XSmall   : max-width = 599.99px
// Breakpoints.Small    : min-width = 600px     and max-width = 959.99px
// Breakpoints.Medium   : min-width = 960px     and max-width = 1279.99px
// Breakpoints.Large    : min-width = 1280px    and max-width = 1919.99px
// Breakpoints.XLarge   : min-width = 1920px

const mobileQuery = '(max-width: 575px)';

@Injectable({providedIn: 'root'})
export class PageService {

  public isMobile = false;

  public checkOnline;
  public checkParentRefresh;
  public checkWindowResize;

  private subjectOnline = new Subject();
  private subjectParentRefresh = new Subject();
  private subjectWindowResize = new Subject();

  constructor(public breakpointObserver: BreakpointObserver) {
    this.checkOnline = this.subjectOnline.asObservable();
    this.checkParentRefresh = this.subjectParentRefresh.asObservable();
    this.checkWindowResize = this.subjectWindowResize.asObservable();

    this.breakpointObserver.observe([
      mobileQuery,
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.breakpoints[mobileQuery]) {
        this.updateMobile(true);
      } else if (result.breakpoints[Breakpoints.XSmall]) {
        // handle XSmall breakpoint
        // console.log(`Size X-Small`, Breakpoints.XSmall);
        this.updateMobile(false);
      } else if (result.breakpoints[Breakpoints.Small]) {
        // handle Small breakpoint
        // console.log(`Size Small`, Breakpoints.Small);
        this.updateMobile(false);
      } else if (result.breakpoints[Breakpoints.Medium]) {
        // handle Medium breakpoint
        // console.log(`Size Medium`, Breakpoints.Medium);
        this.updateMobile(false);
      } else if (result.breakpoints[Breakpoints.Large]) {
        // handle Large breakpoint
        // console.log(`Size Large`, Breakpoints.Large);
        this.updateMobile(false);
      } else if (result.breakpoints[Breakpoints.XLarge]) {
        // handle XLarge breakpoint
        // console.log(`Size X-Large`, Breakpoints.XLarge);
        this.updateMobile(false);
      }
    });
  }

  // Update Mobile Breakpoints :: isMobile
  updateMobile(newValue = false): void { this.isMobile = newValue; }

  // Fullscreen Function
  setFullScreen(element: any): void {
    // Supports most browsers and their versions.
    const requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullscreen;

    if (requestMethod) { // Native full screen.
      requestMethod.call(element);
    }
  };

  // Connection Event
  updateConnection(status: boolean): void { this.subjectOnline.next(status); }

  // Parent Refresh Event
  triggerParentRefresh(): void { this.subjectParentRefresh.next(true); }

  // Window Resize Event
  triggerWindowResize(): void { this.subjectWindowResize.next(true); }
}
