'use strict';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';

@Injectable({providedIn: 'root'})
export class GuestGuard implements CanActivate {
  constructor(private nbAuthService: NbAuthService, private router: Router) {}

  canActivate(): Promise<boolean> {
    // canActive can return Observable<boolean>, which is exactly what isAuthenticated returns
    return new Promise(resolve =>
      this.nbAuthService.isAuthenticated().subscribe(async (authenticated) => {
        if (authenticated) {
          await this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['/secure']));
          resolve(false);
        } else {
          resolve(true);
        }
      })
    );
  }
}
